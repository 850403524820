
  import { computed, defineComponent, ref, getCurrentInstance } from "vue"
  import { CollectionResourceDoc } from '@/models/jsonapi'
  import { Lead } from "@/models/leads";

  export default defineComponent({
    setup(_) {
      const root = getCurrentInstance().proxy
      const dbData = computed<CollectionResourceDoc<string, Lead>>(() => root.$store.state.leads.data)

      root.$store.dispatch('leads/load')

      const columns = ref([
          {
            component: () => import("@/components/shared/table/cells/LinkCell.vue"),
            type: "Def",
            key: "id",
            title: "ID",
            sortable: true,
            options: {
              contentFunction: (data:any) => data.item.address.fullName,
              hrefFunction: (data: any) => `/leads/${data.item.id}`
            }
          },
        ]
      )

      return {
        dbData,
        columns
      }
    }
  })
